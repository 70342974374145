import React from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import {calculatePolygonArea, filterElement, formatDate, libs} from "../helper";
import {Button} from "react-bootstrap";
import {toPng} from "html-to-image";
import * as ReactDOM from "react-dom";

function CanvasMapView({ polys, reference, markerMoveListener = () => {}, size = 420, padding = 50 }) {

  const onLoad = React.useCallback(function callback(map) {

    let isMain = polys.length > 1
    let bounds = new window.google.maps.LatLngBounds();

    polys.map(poly => {
      poly.getPath().forEach(element => {
        bounds.extend(element);
      });
      poly.setMap(map);
      calculatePolygonArea(map, poly, false, isMain, markerMoveListener);
    });

    map.fitBounds(bounds, padding);
    map["polys"] = polys

  }, []);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries: libs,
  });

  const options = {
    mapTypeId: "satellite",
    disableDefaultUI: true,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    rotateControl: false,
    panControl: false,
    tilt: 0
  };

  const saveImage = async () => {
    await toPng(ReactDOM.findDOMNode(reference.current), {
      cacheBust: true,
      includeQueryParams: true,
      filter: filterElement
    }).then((dataUrl) => {
      downloadBase64File(dataUrl, )
    });
  }

  function downloadBase64File(contentBase64) {

    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = contentBase64;
    downloadLink.target = '_self';
    downloadLink.download = `avatar-${ formatDate(new Date()) }.png`;
    downloadLink.click();
  }


  return isLoaded ? (
      <GoogleMap
          ref={ reference }
          options={ options }
          mapContainerStyle={ {
            width: `${ size }px`,
            height: `${ size }px`
          } }
          onLoad={ onLoad }
        >
        <Button className={"saveMapButton bi bi-download"} variant={"success"} onClick={ saveImage } />
      </GoogleMap>

  ) : <></>;
}
export default React.memo(CanvasMapView);
