export const containerStyle = {
  height: '800px'
};

export const canvasPolygonOptions = {
  fillOpacity: 0.5,
  clickable: false,
  editable: false,
  draggable: false,
  fillColor: "#4CBDC6",
  strokeColor: "#18769E",
  strokeOpacity: 1,
  strokeWeight: 3,
  zIndex: 2,
};

export const MARKER_COLOR = "#ffffff";

export const libs = ["drawing", "geometry"];
export const POLY_START_ID = 65;

export function calculateCenter(poly) {
  const count = poly.getPath().length;
  let lats = 0;
  let lngs = 0;

  poly.getPath().forEach(element => {
    lats += element.lat();
    lngs += element.lng();
  });

  return { lat: lats / count, lng: lngs / count };
}

export function getAvatarUrl(map, size, useLetters, polys, autoFit = false) {

  let lat = map?.getCenter()?.lat();
  let lng = map?.getCenter()?.lng();

  if (autoFit && polys.length === 1) {
    let center = polys[0].center;
    lat = center.lat;
    lng = center.lng;
  }

  return `https://maps.googleapis.com/maps/api/staticmap?center=${ lat },${ lng }` +
    `&size=${ size }x${ size }` +
    `&zoom=${ map?.zoom }` +
    url_encode_polys(polys) + url_encode_markers(polys, useLetters) +
    `&maptype=${ map?.getMapTypeId() }&key=${ process.env.REACT_APP_MAP_API_KEY }`;
}

function url_encode_polys(polys) {
  let str = '';
  for (let i = 0; i < polys.length; i++) {
    let alpha = Math.round(polys[i].fillOpacity * 255).toString(16); // from dec to hex
    let coords = polys[i].getPath();

    str += `&path=color:0x${ polys[i].strokeColor.substring(1) }|weight:${ polys[i].strokeWeight }`;
    str += `|fillcolor:0x${ polys[i].fillColor.substring(1) }${ alpha }`;
    for (let j = 0; j < coords.length; j++) {
      str += `|${ coords.getAt(j).lat() },${ coords.getAt(j).lng() }`;
    }
    str += `|${ coords.getAt(0).lat() },${ coords.getAt(0).lng() }`;
  }
  return str;
}

function url_encode_markers(polys, useLetters) {
  let str = "";
  for (let i = 0; i < polys.length; i++) {
    let coords = polys[i].center_marker.getPosition();
    str += `&markers=label:${ getLabel(polys[i].m_id, useLetters) }|${ coords.lat() },${ coords.lng() }`;
  }
  return str;
}

export function getLabel(m_id, useLetters) {

  if (useLetters) {
    return String.fromCharCode(m_id);
  }

  return ( m_id - 64 ).toString();
}

export function updateOrAppend(elements, newData) {
  let index = elements.findIndex(element => element.m_id === newData.m_id);
  if (index === -1) {
    elements.push(newData);
    return elements;

  } else {
    elements[index] = newData;
  }
  return elements;
}

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function formatDate(date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    '_-_' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join('-')
  );
}

export const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export async function customStringify(state) {
  let data = [];

  await Promise.all(state.polys.map(async (poly) => {

    let points = poly.points.map((coord) => {
      return { "lat": coord.lat(), "lng": coord.lng() };
    });

    data.push({
      "id": poly.m_id,
      "label": getLabel(poly.m_id, state.useLetters),
      "points": points,
      "center": poly.center_marker.getPosition(),
      "custom_pos": poly.custom_pos,
      "area": poly.area,
    });

  }));

  return JSON.stringify({
    "center": state.center,
    "size": state.size,
    "zoom": state.zoom,
    "useLetters": state.useLetters,
    "fillAreas": state.fillAreas,
    "addressSearch": state.addressSearch,
    "data": data,
  });
}

export function saveAsFile(txt, targetFSImport = undefined) {
  const blob = new Blob([txt]);
  const href = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = `${ targetFSImport || "roof-measurements" }_-_${ formatDate(new Date()) }.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}


export function calculatePolygonArea(map, poly, useLetters = false, draggable = true, markerMoveListener = () => {}) {

  let path = poly.getPath();
  let area = window.google.maps.geometry.spherical.computeArea(path);
  poly.area = area.toFixed(2);
  if (!poly.custom_pos) {
    poly.center = calculateCenter(poly);
  }

  let points = [];
  poly.getPath().forEach(function (element) {
    points.push(element);
  });
  poly.points = points;

  // Add or reposition a marker
  if (poly.center_marker) {
    poly.center_marker.setPosition(poly.center);
  } else {
    poly.center_marker = new window.google.maps.Marker({
      position: poly.marker_position || poly.center,
      label: {
        text: getLabel(poly.m_id, useLetters),
        color: MARKER_COLOR,
      },
      draggable: draggable,
      map: map
    });

    if (poly.marker_position && draggable) {

      window.google.maps.event.addListener(poly.center_marker, "dragend", function (e) {
        markerMoveListener(poly, poly.center_marker.getPosition())
      });
    } else {
      window.google.maps.event.addListener(poly.center_marker, "dragend", function (e) {
        poly.custom_pos = true;
      });
    }

  }

  return poly;

}

export function filterElement(node) {
  const exclusionClasses = ["gmnoprint", "gm-style-cc", "saveMapButton"];
  return !exclusionClasses.some((classname) => node.classList?.contains(classname));
}

